.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .popup-content {
    text-align: center;
  }
  
  .download-button {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .download-button:hover {
    background-color: #0056b3;
  }
  
  /* button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #ccc;
    color: #000;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  } */
  
  .popup-button {
    display: flex;
  }
  
  button:hover {
    background-color: #bbb;
  }
  
  .footer-text {
    font-size: 10px;
  }
  