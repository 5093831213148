@import url("https://fonts.googleapis.com/css2?family=Inter&family=Lato&family=Libre+Baskerville&family=Merriweather:wght@700&family=Work+Sans&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Work Sans", sans-serif;
}

html {
  color: rgb(96 101 123);
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  background: black
}

.container {
  padding: 30px;
  margin-top: 80px;
}

h2 {
  color: white;
  font-size: 48px;
  letter-spacing: -0.2px;
  font-family: "Merriweather", serif;
}

p {
  max-width: 50rem;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 100px;
}

.subtitle {
  margin-left: 19px;
  margin-bottom: 100px;
  margin-top: -27px;
  color: white;
  font-size: 9px;

}

.main-content {
  margin-top: 25px;
  max-width: 50rem;
  width: 100%;
  min-height: 400px;
  height: auto;
  padding: 18px 18px 120px 18px;
  position: relative;
  resize: none;
  box-shadow: 0 12px 50px 0px rgb(109 117 141 / 20%);
  background: black;
  color: white;
  border-radius: 16px;
}

.main-content:focus {
  border: 0.3px solid rgb(43, 45, 56, 0.3);
  outline: none;
}

p,
.main-content {
  font-size: 18px;
  letter-spacing: 1px;
  word-spacing: 2px;
  line-height: 1.6;
}

label {
  color: white;
}

.btn-style {
  display: flex;

}

button {
  position: absolute;
  background: white;
  color: black;
  padding: 16px 32px;
  border: none;
  font-size: 12px;
  letter-spacing: 1px;
  margin: auto;
  display: flex;
  position: relative;
  margin-top: -80px;
  cursor: pointer;
}

button:focus,
button:hover {
  background: blueviolet;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
}

.counter-container {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.center-container {
  text-align: center;
}

/* .centered-image {
  box-shadow: 0 12px 50px 0px rgb(109 117 141 / 20%);
} */

.centered-image {
  width: 194px;
  margin-top: -88px;
  max-width: 100%;
  /* Para garantir que a imagem não ultrapasse a largura do contêiner */
  max-height: 100%;
  /* Para garantir que a imagem não ultrapasse a altura do contêiner */
  vertical-align: middle;
  /* Para centralizar verticalmente dentro do contêiner */
}


@media screen and (max-width: 768px) {
  .centered-image {
    margin-top: 15px;
  }
}

.popup {
  margin-top: 34px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.popup-content {
  text-align: center;
}

button {
  color: #000;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: white;
}

@media screen and (max-width: 768px) {
  .container {
    margin-top: 20px;
  }

  h2 {
    font-size: 28px;
  }

  p {
    font-size: 16px;
    word-spacing: normal;
    margin-bottom: 40px;
    margin-top: 15px;
  }

  .main-content {
    /*border:1px solid*/
    font-size: 16px;
    word-spacing: normal;
  }

  button {
    padding: 12px 28px;
    font-size: 16px;
    margin-top: -70px;
  }

  .counter-container {
    flex-direction: column;
  }

  .counter-container p:first-child {
    margin-bottom: 0;
  }
}

.description {
  font-size: 10px;
  margin-top: -100px;
}

select {
  height: 33px;
  text-align: center;
  font-size: 18px;
  background-color: white;
  margin-right: 13px;
  border: none;
  outline: none;
  margin-top: 5px;
}

.helpe-text {
  text-align: center;
}